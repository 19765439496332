import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "./index.scss";
import metabilityImage from "../images/metabilitySectionImage.png";
import metabilityImageHover from "../images/metabilitySectionImageHovered.png";
import { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import StepperForm from "../components/stepperform/Stepperform.jsx";
import maskGif from "../images/maskgroup.gif";
import addToMailchimp from "gatsby-plugin-mailchimp";

function IndexPage() {
  const [over, setOver] = useState(false);

  const [pdfFormState, setPdfFormState] = useState({
    fname: "",
    email: "",
  });

  const pdfHandleChange = (e) => {
    setPdfFormState({
      ...pdfFormState,
      [e.target.name]: e.target.value,
    });
    console.log(`${e.target.name}: ${e.target.value}`);
  };

  const mailchimpSubmitForm = async (e) => {
    e.preventDefault();
    addToMailchimp(pdfFormState.email, { ...pdfFormState })
      .then((data) => { })
      .catch(() => { });
    document.getElementById("mailchimpSubmitForm").style.display = "none";
    document.getElementById("mailchimpSubmitFormThankyou").style.display =
      "block";

    setPdfFormState({
      fname: "",
      email: "",
    });
  };

  const myRef = useRef();

  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const mouseOver = () => {
    setOver(true);
  };

  const mouseOut = () => {
    setOver(false);
  };

  return (
    <div className="App">
      <Helmet>
        <html lang="en" />
        <title>
          Metability - La nuova practise per esperienze coinvolgenti nel
          Metaverso
        </title>
        <meta
          name="description"
          content="Metability è la nuova offerta di GreatPixel per brand e aziende che vogliono creare esperienze immersive nel Metaverso"
        />
        <meta name="image" content="../images/Group-69.webp" />
        <meta name="keywords" content="metability metaverso" />
        <meta name="slug" content="https://metability.greatpixel.it" />
        <meta property="og:image" content="../images/Group-69.webp" />
        <meta property="og:image:width" content="400" />
        <meta
          property="og:description"
          content="Metability è la nuova offerta di GreatPixel per brand e aziende che vogliono creare esperienze immersive nel Metaverso"
        />
        <meta
          property="og:title"
          content="Metability - La nuova practise per esperienze coinvolgenti nel
          Metaverso"
        />
        <meta
          property="twitter:description"
          content="Metability è la nuova offerta di GreatPixel per brand e aziende che vogliono creare esperienze immersive nel Metaverso"
        />
        <meta
          property="twitter:title"
          content="Metability - La nuova practise per esperienze coinvolgenti nel
          Metaverso"
        />
      </Helmet>
      <div className="layout">
        <div className="heroSectionWrapper">
          <a href="https://greatpixel.it/" className="heroSectionLogo">
            <StaticImage
              src="../images/Group-69.webp"
              alt="logos of met and gp"
              className="heroSectionLogoImage"
            />
          </a>
          <div className="heroSectionBody">
            <div className="heroSectionBodyLeft">
              <h3>Il metaverso è ancora in potenza.</h3>
              <h1>La Metability</h1>
              <h1>è già performance.</h1>
              <button onClick={executeScroll} className="heroSectionButton">
                Parliamo della tua prossima sfida?
              </button>
            </div>
            <div className="heroSectionBodyRight">
              <StaticImage
                className="heroImage"
                src="../images/heroImage.webp"
                alt="hero image"
              />
              <div className="heroImageShadowCustom"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="heroSection"></div>
      <div className="layout">
        <div className="metabilitySection">
          <div
            className="metabilitySectionImage"
            onMouseOver={mouseOver}
            onFocus={mouseOver}
            onMouseOut={mouseOut}
            onBlur={mouseOut}
            role="presentation"
          >
            <img
              src={over ? metabilityImageHover : metabilityImage}
              className="metabilityImage"
              alt="metabilityImage"
            />
          </div>
          <div className="metabilitySectionText">
            <p className="metabilitySectionTextTitle">COS’È LA METABILITY?</p>
            <p className="metabilitySectionTextDescription">
              La nuova offerta di GreatPixel per abilitare{" "}
              <b>innovative modalità di interazione</b> con gli utenti
              attraverso <b>layers esperienziali</b> immersivi, collaborativi,
              gamificati e finanziari.
            </p>
            <StaticImage
              className="metabilitySectionMobileImage"
              src="../images/metabilitySectionImageHovered.png"
              alt="metabilitySectionMobileImage"
              style={{ width: "180px" }}
            />
          </div>
        </div>
      </div>
      <div className="layout">
        <div className="experienceSection">
          <div className="experienceSectionMain">
            <h2 className="experienceSectionMainTitle">
              Esperienze ancora più ricche
            </h2>
            <p className="experienceSectionMainDescription">
              Facciamo tesoro di NEKTA[R] – la nostra knowledge platform che
              integra design, AI, neuromarketing e storytelling – per curare gli
              aspetti di UX e service design. Come?
            </p>
          </div>
          <div className="experienceSectionCards">
            <div className="experienceSectionCard">
              <div className="experienceSectionCardImg">
                <div className="experienceSectionCardImgMask">
                  <StaticImage
                    className="experienceCardImg"
                    src="../images/Metaverse-Glasses.webp"
                    alt="experienceCard1"
                  />
                </div>
              </div>
              <h3 className="experienceSectionCardTitle">Deep Experience</h3>
              <p className="experienceSectionCardDescription">
                Conoscere i meccanismi emotivi della mente ci permette di
                studiare esperienze realmente coinvolgenti.
              </p>
            </div>
            <div className="experienceSectionCard">
              <div className="experienceSectionCardImg">
                <div className="experienceSectionCardImgMask">
                  <StaticImage
                    className="experienceCardImg"
                    src="../images/Metaverse-Imagination.webp"
                    alt="experienceCard2"
                  />
                </div>
              </div>
              <h3 className="experienceSectionCardTitle">Customer Journey</h3>
              <p className="experienceSectionCardDescription">
                L'esperienza viene inserita nel percorso utente creando
                sperimentazioni di senso che raggiungono l’obiettivo: informare,
                vendere, emozionare.
              </p>
            </div>
            <div className="experienceSectionCard">
              <div className="experienceSectionCardImg">
                <div className="experienceSectionCardImgMask">
                  <StaticImage
                    className="experienceCardImg"
                    src="../images/Metaverse-Space.webp"
                    alt="experienceCard3"
                  />
                </div>
              </div>
              <h3 className="experienceSectionCardTitle">Usability</h3>
              <p className="experienceSectionCardDescription">
                Progettazione e testing con il coinvolgimento degli utenti per
                la massima facilità d'uso e basso carico cognitivo.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="companiesSectionContainer">
        <div className="layout">
          <div className="companiesSection">
            <div className="companiesAbout">
              <div className="companyAbout">
                <h2 className="companyAboutTitle">Deep Experience</h2>
                <p className="companyAboutDescription">
                  Anche il metaverso richiede una profonda cura di ergonomia e
                  usabilità.
                </p>
                <p className="companyAboutDescription">
                  Per questo applichiamo i più innovativi strumenti di service
                  design e customer centricity.
                </p>
              </div>
              <div className="companyAbout">
                <h2 className="companyAboutTitle">Tech Expertise</h2>
                <p className="companyAboutDescription">
                  Per vincere le sfide del Web 3.0, grazie alla partnership con
                  Red Hog mettiamo in campo le più avanzate tecnologie per dare
                  vita a spazi ed esperienze personalizzati sulla visione del
                  cliente.
                </p>
              </div>
            </div>
            <StaticImage
              className="companiesSectionExperienceMapImg"
              src="../images/map1.png"
              alt="experience map"
            />
            <StaticImage
              className="companiesSectionExperienceMapMobileImg"
              src="../images/mobile-map.png"
              alt="experience map"
            />
            <div className="companyQuoteSection">
              <StaticImage
                className="companyQuoteSectionMainImg"
                src="../images/quote.png"
                alt="quote"
              />
              <h3 className="companyQuoteSectionQuote">
                <span>Il Metaverso è qui per restare,</span>
                <span>
                  e Metability è la nostra soluzione <br />
                  per viverlo al meglio.
                </span>
              </h3>
              <p className="companyQuoteSectionAuthor">
                — Giovanni Pola, CEO GreatPixel
              </p>
            </div>
            <div className="companyCards">
              <div className="companyCardLight">
                <div className="companyCardMaskLight">
                  <p className="companyCardDescriptionLight">
                    Le dimensioni che il mercato Metaverse raggiungerà entro il
                    2024.
                  </p>
                  <p className="companyCardAuthorLight">
                    Bloomberg Intelligence
                  </p>
                  <h1 className="companyCardTitleLight">$800 Mld</h1>
                </div>
              </div>
              <div className="companyCardDark">
                <div className="companyCardMaskDark">
                  <p className="companyCardDescriptionDark">
                    Gli Italiani che conoscono il concetto di realtà virtuale.
                  </p>
                  <p className="companyCardAuthorDark">Ipsos</p>
                  <h1 className="companyCardTitleDark">84%</h1>
                </div>
              </div>
              <div className="companyCardLight">
                <div className="companyCardMaskLight">
                  <p className="companyCardDescriptionLight">
                    I nuovi posti di lavoro che nasceranno nel Metaverso entro
                    il 2027.
                  </p>
                  <p className="companyCardAuthorLight">BBC</p>
                  <h1 className="companyCardTitleLight">10.000</h1>
                </div>
              </div>
            </div>
            <div className="companyPdfDownloadSection">
              <h1>Scarica il nostro free report sul Metaverso</h1>
              <p>
                Scopri i case study più interessanti, le opportunità di business
                e il valore aggiunto di un’esperienza immersiva per il tuo Brand
              </p>
              <p id="mailchimpSubmitFormThankyou">
                Ti abbiamo inviato il report alla mail che ci hai indicato.
                Buona lettura!
              </p>
              <form
                onSubmit={mailchimpSubmitForm}
                name="pdfdownloads"
                id="mailchimpSubmitForm"
              >
                <input type="hidden" name="form-name" value="pdfdownloads" />
                <div className="formInputs">
                  <input
                    type="text"
                    id="pdfName"
                    name="fname"
                    placeholder="Il tuo nome*"
                    onChange={pdfHandleChange}
                    value={pdfFormState.fname}
                    style={{ marginRight: "0.5rem" }}
                    required
                  />
                  <input
                    type="email"
                    id="pdfEmail"
                    name="email"
                    placeholder="La tua email*"
                    onChange={pdfHandleChange}
                    value={pdfFormState.email}
                    style={{ marginLeft: "0.5rem" }}
                    required
                  />
                </div>
                <p>
                  *Scaricando il report accetti di iscriverti alla Newsletter di
                  GreatPixel e Metability
                </p>
                <input
                  type="submit"
                  value="SCARICA REPORT"
                  className="pdfFormButton"
                  disabled={!pdfFormState.fname || !pdfFormState.email}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="layout">
        <div className="preFooterSection">
          <div className="preFooterSectionText">
            <h2>Ti aspettiamo nel nostro Metaoffice</h2>
            <div className="preFooterSectionCta">
              <a
                className="preFooterSectionCtaBtn"
                href="https://spatial.io/s/Metability-Office-Team-62e25eeab1c83600019a4808"
                target="_blank"
                rel="noreferrer"
              >
                ENTRA
              </a>
            </div>
          </div>
          <img src={maskGif} alt="Spatial" />
        </div>
      </div>
      <div className="eventsSectionContainer">
        <div className="layout">
          <div className="eventsSection">
            <div className="eventSectionHeading">
              <h2>Disegniamo e implementiamo</h2>
              <h2>esperienze in molti ambiti</h2>
            </div>
            <div className="eventSectionCard">
              <div className="eventSectionText">
                <h2 className="eventSectionTitle">Business Gamification</h2>
                <p className="eventSectionDescription">
                  Portiamo le dinamiche del gaming nei processi di business, sia
                  interni che B2B e B2C. Collection di punti, missioni,
                  esperienze interattive e reward diventano un’ulteriore leva di
                  coinvolgimento e interazione con gli utenti.
                </p>
              </div>
              <StaticImage
                className="eventSectionImage"
                src="../images/Rectangle-8.png"
                alt="event1"
              />
            </div>
            <div className="eventSectionCardWrapper">
              <div className="eventSectionCard">
                <div className="eventSectionText">
                  <h2 className="eventSectionTitle">
                    Immersive Digital Events
                  </h2>
                  <p className="eventSectionDescription">
                    Grazie alle piattaforme immersive 3D e agli strumenti AR e
                    VR trasformiamo gli eventi digitali in esperienze da vivere
                    a 360°. Nel metaverso, le aree virtuali interattive
                    diventano occasione di aggregazione ed engagement.
                  </p>
                </div>
                <StaticImage
                  className="eventSectionImage"
                  src="../images/Rectangle-79.webp"
                  alt="event2"
                />
              </div>
            </div>
            <div
              className="eventSectionCard"
              style={{
                color: "white",
                background:
                  "radial-gradient(100% 117.96% at 28.49% 0%, rgba(75, 77, 80, 0.5) 0%, rgba(22, 25, 28, 0.5) 100%)",
              }}
            >
              <div className="eventSectionText">
                <h2 className="eventSectionTitle">VR Showrooming</h2>
                <p className="eventSectionDescription">
                  Creiamo shop ed esposizioni virtuali dei tuoi prodotti in
                  ambienti 3D, contribuendo a esprimere un'identità di brand
                  differenziante e ad arricchire il percorso di navigazione per
                  utente.
                </p>
              </div>
              <StaticImage
                className="eventSectionImage"
                src="../images/Rectangle-80.webp"
                alt="event3"
              />
            </div>
            <div className="eventSectionCardWrapper">
              <div className="eventSectionCard">
                <div className="eventSectionText">
                  <h2 className="eventSectionTitle">Contentful Experiences</h2>
                  <p className="eventSectionDescription">
                    Arricchiamo gli ecosistemi digitali a partire da contenuti
                    ed esperienze basate su AR e VR, che contribuiscono a
                    rendere i brand unici e innovativi offrendo agli utenti
                    esperienze originali per attrarre, affascinare, conquistare.
                  </p>
                </div>
                <StaticImage
                  className="eventSectionImage"
                  src="../images/Rectangle-10.webp"
                  alt="event4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={myRef} className="stepperForm">
        <StepperForm />
      </div>
      <div className="footerSectionContainer">
        <div className="layout">
          <div className="footerSection">
            <div className="footerSectionBottom">
              <div className="footerSectionBottomLeft">
                <div className="footerSectionBottomLeftTopSection">
                  <p>Un’iniziativa</p>
                  <a href="https://greatpixel.it/">
                    <StaticImage
                      className="footerSectionBottomLeftImageLogo"
                      src="../images/greatpixelfooterlogo.png"
                      alt="footerSectionBottomLeftImageLogo"
                    />
                    <StaticImage
                      className="footerSectionBottomLeftImageCta"
                      src="../images/footerlogocta1.png"
                      alt="footerSectionBottomLeftImageLogocta"
                    />
                  </a>
                </div>
                <StaticImage
                  className="footerSectionBottomLeftImage"
                  src="../images/Metability-logotype-negative.png"
                  alt="footerSectionBottomLeftImage"
                />
              </div>
              <div className="footerSectionBottomRight">
                <div className="footerSectionBottomRightCol">
                  <p>Vediamoci</p>
                  <p className="footerSectionBottomRightAddress">
                    Via G. Ventura, 15, 20134 Milano (MI)
                  </p>
                </div>
              </div>
            </div>
            <p className="copyRight">
              © Copyright 2023 GreatPixel S.r.l. | Tutti i diritti riservati |
              C.F. e P.I. 08259590969 | SDI: W7YVJK9 | P.E.C.
              pro-commerce@arubapec.it
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
