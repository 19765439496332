import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

import "./stepperform.scss";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const steps = ["", ""];

const StepperForm = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleComplete = () => {
    if (
      surveyFormState.checkbox1 === true ||
      surveyFormState.checkbox2 === true ||
      surveyFormState.checkbox3 === true ||
      surveyFormState.checkbox4 === true ||
      surveyFormState.checkbox5 === true ||
      surveyFormState.checkbox6 === true ||
      surveyFormState.checkbox7 === true ||
      surveyFormState.checkbox8 === true ||
      surveyFormState.checkbox9 === true
    ) {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
      document.getElementById("checkboxWarning").style.display = "none";
    } else {
      document.getElementById("checkboxWarning").style.display = "block";
    }
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  const [surveyFormState, setSurveyFormState] = useState({
    name: "",
    email: "",
    website: "",
    telefone: "",
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
  });

  const surveyHandleSubmit = (e) => {
    if (surveyFormState.name.length > 0 && surveyFormState.email.length > 0) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "surveyform",
          ...surveyFormState,
        }),
      }).catch((error) => alert(error));

      e.preventDefault();
      setSurveyFormState({
        name: "",
        email: "",
        website: "",
        telefone: "",
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        checkbox6: false,
        checkbox7: false,
        checkbox8: false,
        checkbox9: false,
      });
      handleComplete();
    } else {
      document.getElementById("detailsWarning").style.display = "block";
    }
  };

  return (
    <div className="layout">
      <Box sx={{ width: "100%" }} className="stepperWrapper">
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <div className="resetForm">
                Fantastico, hai completato il tuo primo passo per entrare nel
                Metaverso! Torniamo presto per parlare insieme della tua idea.
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <form
                onSubmit={surveyHandleSubmit}
                name="surveyform"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="surveyform" />
                {(activeStep === 0 && (
                  <div className="stepOneWrapper">
                    <h1 className="stepperFormHeading">
                      In quali ambito possiamo aiutarti?
                    </h1>
                    <div className="stepperInputs">
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox1"
                          id="checkbox1"
                          value="Employee Engagement"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox1: !surveyFormState.checkbox1,
                            })
                          }
                        />
                        <label htmlFor="checkbox1">Employee Engagement</label>
                      </div>
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox2"
                          id="checkbox2"
                          value="Training & Learning"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox2: !surveyFormState.checkbox2,
                            })
                          }
                        />
                        <label htmlFor="checkbox2">Training & Learning</label>
                      </div>
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox3"
                          id="checkbox3"
                          value="Sales"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox3: !surveyFormState.checkbox3,
                            })
                          }
                        />
                        <label htmlFor="checkbox3">Sales</label>
                      </div>
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox4"
                          id="checkbox4"
                          value="Customer experience"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox4: !surveyFormState.checkbox4,
                            })
                          }
                        />
                        <label htmlFor="checkbox4">Customer experience</label>
                      </div>
                    </div>
                    <div className="stepperInputs">
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox5"
                          id="checkbox5"
                          value="Awareness & Branding"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox5: !surveyFormState.checkbox5,
                            })
                          }
                        />
                        <label htmlFor="checkbox5">Awareness & Branding</label>
                      </div>
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox6"
                          id="checkbox6"
                          value="Customer Loyalty"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox6: !surveyFormState.checkbox6,
                            })
                          }
                        />
                        <label htmlFor="checkbox6">Customer Loyalty</label>
                      </div>
                      <div className="stepperInput">
                        <input
                          type="checkbox"
                          name="checkbox7"
                          id="checkbox7"
                          value="Altro"
                          onClick={() =>
                            setSurveyFormState({
                              ...surveyFormState,
                              checkbox7: !surveyFormState.checkbox7,
                            })
                          }
                        />
                        <label htmlFor="checkbox7">Altro</label>
                      </div>
                      <div className="stepperHiddenInputs">
                        <input
                          key={1}
                          type="text"
                          id="yourname"
                          name="name"
                          placeholder="Il tuo nome"
                          style={{ marginRight: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              name: e.target.value,
                            })
                          }
                          value={surveyFormState.name}
                        />
                        <input
                          key={2}
                          type="text"
                          id="youremail"
                          name="email"
                          placeholder="La tua email"
                          style={{ marginLeft: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              email: e.target.value,
                            })
                          }
                          value={surveyFormState.email}
                          required
                        />
                      </div>
                      <div className="stepperHiddenInputs">
                        <input
                          key={3}
                          type="text"
                          id="agencysite"
                          name="website"
                          placeholder="Il sito della tua azienda"
                          style={{ marginRight: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              website: e.target.value,
                            })
                          }
                          value={surveyFormState.website}
                          required
                        />
                        <input
                          key={4}
                          type="text"
                          id="telephonenumber"
                          name="telefone"
                          placeholder="Il tuo numero di telefono"
                          style={{ marginLeft: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              telefone: e.target.value,
                            })
                          }
                          value={surveyFormState.telefone}
                          required
                        />
                      </div>
                    </div>
                  </div>
                )) ||
                  (activeStep === 1 && (
                    <div className="stepOneWrapper">
                      <h1 className="stepperFormHeading">Parliamone insieme</h1>
                      <div className="stepperInputs">
                        <input
                          key={1}
                          type="text"
                          id="yourname"
                          name="name"
                          placeholder="Il tuo nome*"
                          style={{ marginRight: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              name: e.target.value,
                            })
                          }
                          value={surveyFormState.name}
                        />
                        <input
                          key={2}
                          type="text"
                          id="youremail"
                          name="email"
                          placeholder="La tua email*"
                          style={{ marginLeft: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              email: e.target.value,
                            })
                          }
                          value={surveyFormState.email}
                          required
                        />
                      </div>
                      <div className="stepperInputs">
                        <input
                          key={3}
                          type="text"
                          id="agencysite"
                          name="website"
                          placeholder="Il sito della tua azienda"
                          style={{ marginRight: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              website: e.target.value,
                            })
                          }
                          value={surveyFormState.website}
                          required
                        />
                        <input
                          key={4}
                          type="text"
                          id="telephonenumber"
                          name="telefone"
                          placeholder="Il tuo numero di telefono"
                          style={{ marginLeft: "0.5rem" }}
                          onChange={(e) =>
                            setSurveyFormState({
                              ...surveyFormState,
                              telefone: e.target.value,
                            })
                          }
                          value={surveyFormState.telefone}
                          required
                        />
                      </div>
                    </div>
                  ))}
              </form>
              <Stepper activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label, index) => (
                  <Step key={index} completed={completed[index]}>
                    <StepLabel color="inherit">{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  pt: 2,
                }}
              >
                <p id="checkboxWarning" style={{ display: "none" }}>
                  Per favore seleziona almeno una delle opzioni
                </p>
                <p id="detailsWarning" style={{ display: "none" }}>
                  Per favore completa i campi obbligatori
                </p>
                {activeStep !== steps.length &&
                  (completed[activeStep] ? (
                    <Typography
                      variant="caption"
                      sx={{ display: "inline-block" }}
                    >
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button
                      className="stepperFormCtaBtn"
                      onClick={
                        activeStep === 0
                          ? handleComplete
                          : activeStep === 1
                          ? surveyHandleSubmit
                          : alert("error in button click")
                      }
                    >
                      {completedSteps() === totalSteps() - 1
                        ? "Invia messaggio"
                        : completedSteps() === 0
                        ? "AVANTI"
                        : "none"}
                    </Button>
                  ))}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </div>
  );
};

export default StepperForm;
